<template>
  <div class="course-container">
    <div class="course-container-title">安全设置</div>
    <div v-if="active == 1">
      <div class="setting-item">
        <div class="left">
          <div class="label">账户密码</div>
          <div class="field">密码：******</div>
        </div>
        <div class="right cursor" @click="next(2)">修改</div>
      </div>
      <!-- <div class="setting-item">
        <div class="left">
          <div class="label">手机号</div>
          <div class="field">密码：******</div>
        </div>
        <div class="right cursor" @click="next(3)">修改</div>
      </div> -->
    </div>
    <div v-if="active == 2">
      <div class="steps">
        <a-steps :current="current" >
          <a-step>发送验证码</a-step>
          <a-step>填写新密码</a-step>
          <a-step>修改成功</a-step>
        </a-steps>
        <a-tabs class="tabs" :default-active-key="current" :active-key="current" @change="change" >
          <a-tab-pane :key="1" title="Tab 1">
            <a-form :model="addForm1" :style="{width:'500px', margin: '50px auto 0'}" @submit="submit1" class="login-form" :rules="rules">
              <a-form-item
                hide-label
              >
                <a-input v-model="phone" disabled placeholder="请输入手机号" />
              </a-form-item>
              <a-form-item field="code" hide-label
                :rules="[{ required: true, message: '验证码不能为空'}]"
                :validate-trigger="['change','input']"
              >
                <a-input v-model="addForm1.code" placeholder="请输入验证码">
                  <template #append>
                    <a-button type="text" style="color:#4F17A8;" @click="getCode" v-if="!isShow" :disabled="disabled">获取验证码</a-button>
                    <a-button type="text" style="color:#165BD3;" v-else :disabled="disabled">{{text}}</a-button>
                  </template>
                </a-input>
              </a-form-item>
              <a-space align="center" direction="vertical">
                <a-button html-type="submit" :loading="loading" style="background:#4F17A8; color:#fff;" >下一步</a-button>
              </a-space>
            </a-form>
          </a-tab-pane>
          <a-tab-pane :key="2" title="Tab 2">
            <a-form :model="addForm2" :style="{width:'500px', margin: '50px auto 0'}" @submit="passwordSubmit" class="login-form" :rules="rules">
              <a-form-item field="password" hide-label 
                :rules="[
                  { required: true, message: '新密码不能为空'},
                  { match: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,16}$/, message: '大写字母、小写字母、数字和特殊符号，这4类必须包括3类，长度8到16位'},
                ]"
                :validate-trigger="['change','input']"
              >
                <a-input-password v-model="addForm2.password" placeholder="请输入新密码"/>
              </a-form-item>
              <a-form-item field="confirmpassword" hide-label
                :rules="[
                  { required: true, message: '确认新密码不能为空'},
                  { match: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,16}$/, message: '大写字母、小写字母、数字和特殊符号，这4类必须包括3类，长度8到16位'},
                ]"
                :validate-trigger="['change','input']"
              >
                <a-input-password v-model="addForm2.confirmpassword" placeholder="请输入确认新密码"/>
              </a-form-item>
              <a-space align="center" direction="vertical">
                <a-button html-type="submit" :loading="loading" style="background:#4F17A8; color:#fff;" >下一步</a-button>
              </a-space>
            </a-form>
          </a-tab-pane>
          <a-tab-pane :key="3" title="Tab 3">
            <div style="line-height: 100px;text-align: center;font-size: 18px;">修改成功</div>
            <div style="text-align: center;">
              <a-button style="background:#207AFF; color:#fff;" @click="passwordEditOk">我知道了</a-button>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { loginValCode, retrievePwd, getUserInfo } from '@/api/login';
import { Message} from '@arco-design/web-vue';
import md5 from 'js-md5';

export default {
  setup () {
    const route = useRoute()
    const router = useRouter()
    const state = reactive({
      phone: '',
      active: 1, // 1 默认  2 修改密码  3修改手机
      current: 1, // 1, 2, 3 步
      addForm1: {
        code: ''
      },
      addForm2: {
        password: '',
        confirmpassword: '',
        code: ''
      },
      text: '',
      loading: false,
      isShow: false,
      disabled: false,
      // text2: '',
      // loading2: false,
      // isShow2: false,
      // disabled2: false,
      rules: {
        phone: [{ required: true, message: '手机号不能为空！'},{match: /^1[3456789]\d{9}$/,message: '请输入有效手机号'}],
        code: [{ required: true, message: '请输入验证码'}],
      }
      
    })
    watch(() => route.query.type, (newType, oldType) => {
      state.active = newType || 1
    }, { immediate: true })
    // 获取
    const getInfo = (id) => {
      getUserInfo({}).then(res => {
        if (res.code === 20000) {
          state.phone = res.result.phone;
        }
      })
    }
    getInfo()
    const change = val => {
      state.current = val
    }
    const next = (type) => {
      router.push({ path: '/account/setting', query: { type } })
    }
    const getCode = () => {
      loginValCode({}).then(res => {
        if (res.code === 20000) {
          Message.success('发送成功')
          state.isShow = true
          state.text = '重新发送60s'
          let num = 10
          const timer = setInterval(() => {
            num--
            if (num <= 0) {
              clearInterval(timer)
              state.disabled = false
              state.text = '重新发送'
            } else {
              state.disabled = true
              state.text = `重新发送${num}s`
            }
          }, 1000)
        }
      })
    }
    const submit1 = ({values, errors}) => {
      if (!errors) {
        state.loading = true;
        state.loading = false;
        state.current = 2;
      }
    }
    const passwordSubmit = ({values, errors}) => {
      if (!errors) {
        if (state.addForm2.password !== state.addForm2.confirmpassword) {
          Message.error('确认密码不一致');
          return;
        }
        state.loading = true
        retrievePwd({
          valCode: state.addForm1.code,
          password: md5(state.addForm2.password),
        }).then(res => {
          state.loading = false;
          if (res.code === 20000) {
            state.loading = false;
            state.current = 3;
          }
        })
      }
    }
    const passwordEditOk = () => {
      state.active = 1;
      state.current = 1;
    }
    return { 
      ...toRefs(state),
      getCode,
      submit1,
      passwordSubmit,
      passwordEditOk,
      change,
      next
    }
  }
}
</script>

<style lang="less">
.tabs {
  .arco-tabs-nav {
    display: none;
  }
}
</style>

<style lang="less" scoped>
/deep/.arco-steps-item-process .arco-steps-icon{
  background-color: #4F17A8;
}
/deep/.arco-steps-item-finish .arco-steps-icon {
  color: #4F17A8;
  background: rgba(147, 32, 255, 0.1);
}
  .course-container {
    width: 100%;
    min-height: calc(100vh - 180px);
    padding: 20px 30px 50px;
    box-sizing: border-box;
    background-color: #fff;
    .course-container-title {
      color: #323233;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 20px;
    }
  }
  .setting-item {
    padding: 22px 0;
    border-bottom: 1px solid #E9E9E9;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #207AFF;
    .left {
      flex: 1;
      .label {
        color: #323233;
      }
      .field {
        margin-top: 12px;
        color: #7B7B7B;
      }
    }
  }
  .steps {
    width: 550px;
    margin: 60px auto 0;
  }
  </style>